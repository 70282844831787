import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Window } from './Window';
import { theme } from '../constants';
import SanitizationDevice from './SanitizationDevice';

const Wrapper = styled.div`
  // min-height: 600px;
  max-width: ${theme.width.tablet};
  margin: 0 auto;
`

const rainbow = keyframes`
  0% { color: violet; }
  16.5% { color: indigo; }
  33% { color: blue; }
  55.5% { color: green; }
  66% { color: yellow; }
  82.5% { color: orange; }
  100% { color: red; }
`

const blink = keyframes`
  to {
    visibility: hidden;
  }
`

const RainbowText = styled.span`
  animation: ${rainbow} 1s infinite;
`
const BlinkText = styled.span`
  animation: ${blink} 1s steps(5, start) infinite;
`

export default () => {
  return (
    <Wrapper>
      {/* <Window x={`${960/2-340/2}px`} y={'70px'} width={'340px'} height={'250px'}>
        <br/>
        <a href="/order">BLOCK ADVERTS, VIRUSES, AND ADULT CONTENT.</a>
        <br />
        <br/>
        <a href="/order">KEEP IT CLEAN.</a>
      </Window> */}
      {/* <Window x={'100px'} y={'230px'} width={'140px'} height={'270px'}>
        <br/>
        <a href="/order">ORDER TODAY</a>
        <br />
        <br/>
        <a href="/order"><BlinkText>ONLY $10/mo</BlinkText></a>
      </Window> */}
      {/* <Window x={`${960/2-290/2}px`} y={`${250+70+10}px`} width={'290px'} height={'320px'}>
        <br/>
        <a href="/order"><u>WORKS ON ALL<br/>YOUR DEVICES</u></a>
        <br />
        <br/>
        <a href="/order">SUPPORTS iOS,<br/>ANDROID, WINDOWS,<br/> MAC, AND LINUX</a>
      </Window> */}
      {/* <Window x={'500px'} y={'180px'} width={'120px'} height={'300px'}>
        <br/>
        <a href="/order">YOU WIN!!!</a>
        <br />
        <br/>
        <a href="/order"><RainbowText>FIRST MONTH FREE</RainbowText></a>
      </Window> */}
      <div style={{margin: '25px auto 0 auto', width: '100%', maxWidth: '700px'}}>
        <SanitizationDevice />
        </div>
    </Wrapper>
  )
}