import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const SanitizationDevice = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fluid: {originalName: {eq: "sanitization-device.png"}}) {
        id
        resize(width: 700, quality: 100) {
          src
        }
      }
    }
  `)

  return (
    <img src={data.imageSharp.resize.src} {...props} />
  )
}

export default SanitizationDevice
