import React from 'react'
import styled from 'styled-components';
import { Slide, Title, Button } from './Etc';
import Wifi from '../images/wifi.png'
import OrderBox from '../images/orderBox.png'
import { Link } from 'gatsby';

const Step = styled.div`
  padding: 15px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const StepNumber = styled.span<{bg?: string}>`
  display: inline-block;
  width: 60px;
  height: 60px;
  background-color: ${p => p.bg ? p.bg : 'rgba(255, 255, 255, 0.2)'};
  border-radius: 50%;
  font-size: 45px;
  text-align: center;
  vertical-align: baseline;
  margin: 0 40px 0 0;
  line-height: 60px;
`

export const StepDescription = styled.span`
  display: inline-block;
  font-weight: 300;
  font-size: 50px;
  flex: 1;
`
const StepTip = styled.div`
  font-size: 18px;
  position: absolute;
`

const AndroidImage = styled.div`
  width: 400px;
  height: 400px;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,0));
`

export const HowItWorks = () => {
  return (
    <Slide>
      <Title>It's easy.</Title>
      <Step>
        <StepNumber>1</StepNumber>
        <StepDescription>Order &amp; install <StepTip>We'll send a technician to help!</StepTip></StepDescription>
        <img src={OrderBox}></img>
      </Step>
      <Step>
        <StepNumber>2</StepNumber>
        <StepDescription>Connect to WiFi <StepTip>You can connect via ethernet too!</StepTip></StepDescription>
        <AndroidImage><img width="100%" src={Wifi}></img></AndroidImage>
      </Step>
      <Step style={{display: 'grid', justifyItems: 'center', padding: '70px 0'}}>
        <StepNumber style={{margin: 0}} bg="#779750">✓</StepNumber>
        <StepDescription>Start browsing!</StepDescription>
      </Step>
      <Step>
        <Link to="/order" style={{flex: 1, marginBottom: '40px'}}>
          <StepDescription style={{ margin: '20px auto 5px auto', width: '100%', color: '#34b4ff', textDecoration: 'underline', textAlign: 'center' }}>
            Give it a shot.
          </StepDescription>
          <span style={{ display: 'block', color: 'gray', margin: '0 auto', textAlign: 'center' }}>(No contracts, first month free)</span>
        </Link>
      </Step>
    </Slide>
  )
}
