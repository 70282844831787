import React from 'react'
import styled from 'styled-components';
import { Slide, Title } from './Etc';
import { theme } from '../constants';
import { Advert } from './images/Advert';
import {Plug} from './images/Plug';
import { TV } from './images/TV';
import DownloadIcon from './images/DownloadIcon';
import Computer from './images/Computer';

const Feature = styled.div`
  flex: 1;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
`

const FeatureList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 70px;

  & ${Feature} {
    border-right: 1px dashed rgba(255, 255, 255, 0.3);
  }
  
  & ${Feature}:last-child {
    border: none;
  }

  @media (max-width: ${theme.width.tablet}) {
    flex-flow: column nowrap;

    & ${Feature} {
      border-right: none;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
    }
  }
`

const FeatureTitle = styled.b`
  display: block;
  height: 46px;
`

const FeatureIcon = styled.div`
  margin: 0 auto;
  width: 75px;
`

export const Features = () => {
  return (
    <Slide>
      <Title>Drop-in solution.</Title>
      <FeatureList>
        <Feature>
          <FeatureTitle>Good bye,<br/>advertisements!</FeatureTitle>
          <FeatureIcon><Advert /></FeatureIcon>
          Removes most banners, video, and page-redirection advertisement. Don't bother waiting to skip the ad.
        </Feature>
        <Feature>
          <FeatureTitle>Good bye,<br/>viruses!</FeatureTitle>
          <FeatureIcon><DownloadIcon /></FeatureIcon>
          Stops ransomware, malware, spyware, and other viruses by scanning downloads before they reach your device.
        </Feature>
        <Feature>
          <FeatureTitle>Good bye,<br/>adult content!</FeatureTitle>
          <FeatureIcon><TV /></FeatureIcon>
          Smart filters and internet censorship based on deep content inspection and a large classification database which is regularly updated.
        </Feature>
        <Feature>
          <FeatureTitle>Good bye,<br/>configuration!</FeatureTitle>
          <FeatureIcon><Plug/></FeatureIcon>
          A plug-and-play solution. Connect your device to the system once, and you're all set to go! Our support team is just a call away.
        </Feature>
        <Feature>
          <FeatureTitle>Every. Single. Device.</FeatureTitle>
          <FeatureIcon><Computer /></FeatureIcon>
          Supports iOS, Android, Windows, Mac, and Linux! Protect every device at once! <a href="/SupportMatrix">Click here</a> to see the exhausted list of supported devices.
        </Feature>
      </FeatureList>
    </Slide>
  )
}
