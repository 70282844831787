import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Product from "../components/Product"
import Newsletter from "../components/Newsletter"
import { Features } from "../components/Features"
import { HowItWorks } from '../components/HowItWorks'
import { TryItNow } from '../components/TryItNow'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Product />
    <Features />
    <HowItWorks />
    <Newsletter />
    <TryItNow />
  </Layout>
)

export default IndexPage
