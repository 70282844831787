import React, { useState } from 'react'
import styled from 'styled-components';
import { theme } from '../constants';
import { Button } from './Etc'
import { Divider } from './Divider';

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  font-size: 2rem;
  background: ${theme.secondary};
  color: black;
`

const FormWrapper = styled.div`
  max-width: ${theme.width.tablet};
  align-items: center;
  margin: 0 auto;
  padding: 45px 0;
  display: flex;

  @media (max-width: ${theme.width.tablet}) {
    flex-direction: column;
  }
`

const EmailInput = styled.form<{error: boolean}>`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  
  & input {
    height: 40px;
    width: 100%;
    font-weight: 600;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid ${p => p.error ? 'red' : 'rgba(0, 0, 0, 0.1)'};
    padding: 5px;
    margin: 0;
    color: ${theme.accent};
    border-radius: 5px;
    font-size: 18px;

    &::placeholder {
      text-align: center;
      color: ${p => p.error ? 'red' : 'rgba(0, 0, 0, 0.1)'};
    }

    &:focus {
      background-color: rgba(255, 255, 255, 0.5);
    }

    user-select: none;
  }
`


export default () => {
  const [email, _setEmail] = useState<string>('')
  const [enterEmail, setEnterEmail] = useState<boolean>(false)
  const setEmail = (val: string) => {
    _setEmail(val)
    setEnterEmail(false)
  }

  function onSubmit(e: Event) {
    if (!email) {
      e.preventDefault()
      setEnterEmail(true)
    }
  }
  
  return (
    <div style={{marginTop: '45px'}}>
      <Divider height={25} position="topLeft" color={theme.secondary} />
      <Wrapper>
        <FormWrapper>
          <div style={{flex: 2, padding: '0 15px'}}>
            <b>Join our community!</b><br/>
            Get updates and promotional offers.
          </div>
          <EmailInput style={{flex: 1}} error={enterEmail} onSubmit={onSubmit} action="https://internetsanitizer.us4.list-manage.com/subscribe/post" method="POST">
            <input type="hidden" name="u" value="bd5a63a898ad3cf7a28582d63" />
            <input type="hidden" name="id" value="eba2bf397c" />

            <input value={email} onChange={e => setEmail(e.target.value)} placeholder="your@email.here" name="MERGE0" type='email' />
            <Button type="submit">Sign up</Button>
          </EmailInput>
        </FormWrapper>
      </Wrapper>
      <Divider height={25} position="bottomRight" color={theme.secondary} />
    </div>
  )
}