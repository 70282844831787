import React from 'react'
import { Button } from './Etc'
import styled from 'styled-components'
import { StepDescription } from './HowItWorks'
import { Link } from 'gatsby'

const Wrapper = styled.div`
    display: flex;
    margin: 60px auto 30px auto;
    max-width: 300px;
`

export const TryItNow = () => {
    return (
        <>
            <br/>
            <br/>
            <br/>
            <StepDescription style={{ margin: '20px auto 5px auto', width: '100%', textAlign: 'center' }}>
                    Questions or comments?
            </StepDescription>
            <Link to="/order?type=GENERAL_INQUIRY">
                <StepDescription style={{ margin: '20px auto 5px auto', width: '100%', color: '#34b4ff', textDecoration: 'underline', textAlign: 'center' }}>
                    Contact us.
                </StepDescription>
            </Link>
        </>
    )
}